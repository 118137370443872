import React, { useContext } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Paper,
  Button
} from "@mui/material";

//formats
import { decimalTwoFormat } from "../../utils/formats.js";
import { useRecoilState } from "recoil";
import { depositListState } from "../../states/deposit-list.js";

function DepositDialog() {
  const [openDepositDialog, setOpenDepositDialog] = useRecoilState(
    depositListState.openDepositDialog
  );

  const handleClose = () => {
    setOpenDepositDialog(false);
  };

  //mock data
  let Refno = "00000126";
  let time = "Oct 17 ,2022, 2:23 AM";
  let status = "Success";
  let type = "Deposits";
  let merchant = "qrs";
  let customer = "-";
  let amount = 20000;
  let fee = 600;
  let vat = 42;
  let amountNet = 19358;
  let balance = 19358;
  let remark = "-";

  return (
    <div>
      <Dialog open={openDepositDialog} onClose={handleClose}>
        <Box maxWidth={500}>
          <Paper sx={{ p: 2 }}>
            <DialogTitle textAlign={"left"}>#4324</DialogTitle>
            <DialogContent>
              <Grid
                container
                rowSpacing={1.5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Refno
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {Refno}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Time
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {time}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {status === "Success" ? <Button>{status}</Button> : null}
                  {status === "Processing" ? <Button> {status} </Button> : null}
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="green"
                  >
                    {type}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Merchant
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {merchant}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Customer
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {customer}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {decimalTwoFormat(amount)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Fee
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {decimalTwoFormat(fee)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Vat
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {decimalTwoFormat(vat)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Amount Net
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {decimalTwoFormat(amountNet)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Balance
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {decimalTwoFormat(balance)}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#79919D"
                  >
                    Remark
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={14}
                    fontWeight="bold"
                    color="#000"
                  >
                    {remark}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Paper>
        </Box>
      </Dialog>
    </div>
  );
}

export default DepositDialog;
