import React from "react";
import {
  Box,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  InputBase
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { alpha, styled } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";

import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

export default function MainAppbar({ open, secondColor, handleDrawerOpen }) {
  return (
    <AppBar position="fixed" open={open} >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none", mr: 0 }) }}
        >
          <MenuIcon sx={{ color: "white" }} />
        </IconButton>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box sx={{ ml: "auto" }}>
          <Stack direction={"row"} spacing={2} alignItems="center">
            {/* <Box
            sx={{
              border: "solid 1px #3f5d6b",
              borderRadius: "6px",
              display: "flex"
            }}
          >
            <Typography
              sx={{ p: 1, backgroundColor: "#3f5d6b" }}
              variant="caption"
              color="#fff"
              fontWeight="bold"
              fontSize={14}
            >
              BALANCE
            </Typography>
            <Typography sx={{ p: 1 }} component="span" fontWeight={"light"}>
              23,239.15 {`  `}thb
            </Typography>
          </Box> */}
            {/* <IconButton>
            <Badge badgeContent={4} color="error">
              <CircleNotificationsIcon />
            </Badge>
          </IconButton> */}
            <Typography
              variant="caption"
              color="#eee"
              fontWeight="bold"
              fontSize={14}
            >
              Merchant : 
            </Typography>
            <Typography
              variant="caption"
              color="#fff"
              fontWeight="bold"
              fontSize={14}
            >
              {localStorage.getItem("name")
                ? localStorage.getItem("name")
                : "test"}
            </Typography>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
