import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Container,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider
} from "@mui/material";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import dragonpayService, { useAPI } from "../services/happypay-api";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import CardAmount from "../components/cards/Amount";

function RenderLineChart() {
  const wrapRef = useRef(null);
  const [width, setWidth] = useState(300);

  useEffect(() => {
    if (wrapRef.current) {
      setWidth(wrapRef.current.offsetWidth);
    }

    window.addEventListener("resize", (e) => {
      if (wrapRef.current) {
        setWidth(wrapRef.current.offsetWidth);
      }
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [wrapRef]);
  return (
    <Box ref={wrapRef}>
      <ResponsiveContainer width={width} height={400}>
        <BarChart
          data={[
            { name: "Sunday", balance: 20 },
            { name: "Monday", balance: 300 },
            { name: "Tuesday", balance: 52.2 },
            { name: "Wednesday", balance: 100 },
            { name: "Thursday", balance: 594 },
            { name: "Friday", balance: 300 },
            { name: "Saturday", balance: 299 }
          ]}
        >
          <Tooltip />
          <XAxis dataKey="name" stroke="#8884d8" />
          <YAxis />
          <Bar dataKey="balance" fill="#8884d8" barSize={30} />

          {/* <Legend
      width={100}
      wrapperStyle={{
        top: 40,
        right: 20,
        backgroundColor: "#f5f5f5",
        border: "1px solid #d5d5d5",
        borderRadius: 3,
        lineHeight: "40px"
      }}
    /> */}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

function MainDashboard() {
  const api = useAPI();
  const [profile, setProfile] = useState(null);
  const [balance, setBalance] = useState(0);
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);

  useEffect(() => {
    api
      .balanceMerchant(localStorage.getItem("token"))
      .then((data) => {
        setProfile(data);
      })
      .catch((error) => {
        const { message, statusCode } = error;
        alert(message);
        if (statusCode == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          setIsLogin(false);
          window.location = "/login";
          return;
        }
      });
  }, []);

  useEffect(() => {
    api
      .profileMerchant(localStorage.getItem("token"))
      .then((data) => {
        setBalance(data?.balances[0]?.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Stack spacing={3}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardAmount
              label={"Balance"}
              amount={profile && profile.balance.toLocaleString('en-US')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardAmount
              label={"Withdraw Balance"}
              amount={profile && profile.balance.toLocaleString('en-US')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardAmount
              label={"Total Deposit"}
              amount={profile && profile?.totalDeposit.toLocaleString('en-US')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardAmount
              label={"Total Withdraw"}
              amount={profile && profile?.totalWithdraw.toLocaleString('en-US')}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Paper>
        <Box sx={{ p: 3, mb: 2 }}>
          <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
            Daily Deposit Chart
          </Typography>
        </Box>
        <RenderLineChart />
      </Paper> */}
    </Stack>
  );
}
export default MainDashboard;
