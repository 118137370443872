import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@mui/material";

import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
//components
import DialogDeposits from "./DepositDialog";

//formats
import { decimalFourFormat } from "../../utils/formats.js";
import { depositListState } from "../../states/deposit-list";

import ChipSuccess from "../commons/ChipSuccess";
import ChipFail from "../commons/ChipFail";
import ChipPending from "../commons/ChipPending";

import EnhancedTableHead from "../commons/EnhancedTableHead";
import { getComparator, stableSort } from "../../utils/table";

function PendingDepositTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [headCells] = useState([
    {
      id: "time",
      label: "Time",
      numeric: false,
      disablePadding: true,
      minWidth: 200
    },
    {
      id: "m_refno",
      label: "Merchant TX Id",
      numeric: false,
      disablePadding: false,
      minWidth: 0
    },
    {
      id: "customerName",
      label: "Customer",
      minWidth: 150,
      numeric: false,
      disablePadding: false
    },
    {
      id: "money",
      label: "Amount",
      // minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "awf",
      label: "Amount (Fee)",
      // minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "fee",
      label: "Fee (%)",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "fee_amount",
      label: "Fee",
      numeric: true,
      disablePadding: false,
      minWidth: 0
    },
    {
      id: "vat",
      label: "Vat 7%",
      numeric: true,
      disablePadding: false,
      minWidth: 0
    },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      numeric: false,
      disablePadding: false
    }
  ]);

  const depositPendingList = useRecoilValue(
    depositListState.pendingDepositList
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table
          // aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
          // size='medium'
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={depositPendingList ? depositPendingList.length : 0}
          />
          <TableBody>
            {stableSort(depositPendingList || [], getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {dayjs(row.dateCreated).format("DD/MMM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell align="center">
                      {row.clientTransactionId}
                    </TableCell>
                    <TableCell align="center">{row.customerName}</TableCell>
                    <TableCell align="center">
                      {decimalFourFormat(row.amount)}
                    </TableCell>
                    <TableCell align="center">
                      {decimalFourFormat(row.amountWithFee)}
                    </TableCell>
                    <TableCell align="center">
                      {decimalFourFormat(row.totalFee)}
                    </TableCell>
                    <TableCell align="center">
                      {decimalFourFormat(row.feeInAmount)}
                    </TableCell>
                    <TableCell align="center">
                      {decimalFourFormat(row.feeInAmount*0.07)}
                    </TableCell>
                    {row.status == "successful" ? (
                      <TableCell align="center">
                        <ChipSuccess />
                      </TableCell>
                    ) : row.status == "pending" ? (
                      <TableCell align="center">
                        <ChipPending />
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <ChipFail />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={depositPendingList ? depositPendingList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DialogDeposits />
    </>
  );
}

export default PendingDepositTable;
