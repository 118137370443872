import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useAPI } from "../../services/happypay-api";
import { useEffect, useState } from "react";
import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";
import moment from "moment";
import DialogSubmitTransferSlip from "./DialogSubmitTransferSlip";

export default function PayslipHistory() {
  const api = useAPI();

  const [slipHistoryList, setSlipHistoryList] = useState(null);

  useEffect(() => {
    api
      .getSlipList("approved,rejected")
      .then((data) => {
        setSlipHistoryList(data);
      })
      .catch();
  }, []);

  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography fontSize={"1.5rem"}>Topup Slip History</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Submit Date</TableHeadCell>
              <TableHeadCell>Amount</TableHeadCell>
              <TableHeadCell>Currency</TableHeadCell>
              <TableHeadCell>Date Time</TableHeadCell>
              <TableHeadCell>Refer link</TableHeadCell>
              <TableHeadCell>Slip</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slipHistoryList &&
              slipHistoryList.map((row) => {
                return (
                  <TableRow>
                    <TableBodyCell>
                      {moment(row.dateCreated).format("DD/MMM/YYYY HH:mm")}
                    </TableBodyCell>
                    <TableBodyCell>{row.amount.toLocaleString('en-US')}</TableBodyCell>
                    <TableBodyCell>{row.currency}</TableBodyCell>
                    <TableBodyCell>
                      {moment(row.dateTransfer).format("DD/MMM/YYYY HH:mm")}
                    </TableBodyCell>
                    <TableBodyCell>{row.linkRefer}</TableBodyCell>
                    <TableBodyCell>{row.slipRefer}</TableBodyCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
