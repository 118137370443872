import React from "react";

import { Card, CardContent, Stack, Typography } from "@mui/material";

export default function CardAmount({ label, amount }) {
  return (
    <Card>
      <CardContent sx={{ mx: 2 }}>
        <Typography sx={{ mb: 1, fontSize: "1.2rem" }} color="text.secondary">
          {label}
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "1.8rem" }}
            fontWeight="bold"
          >
            &#3647;  {amount}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
