import React from "react";
import { Grid, Paper, Container } from "@mui/material";

function SingleBoxLayout({ children }) {
  return (
    <Container
      maxWidth={false}
      sx={{
        background: "rgb(103,42,115)",
        background:
          "-moz-radial-gradient(circle, rgba(103,42,115,1) 0%, rgba(52,36,99,1) 100%)",
        background:
          "-webkit-radial-gradient(circle, rgba(103,42,115,1) 0%, rgba(52,36,99,1) 100%)",
        background:
          "radial-gradient(circle, rgba(103,42,115,1) 0%, rgba(52,36,99,1) 100%)",
        filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="#672a73",endColorstr="#342463",GradientType=1)`
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 3, py: 5, backgroundColor: "rgba(255,255,255,0.8)" }}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SingleBoxLayout;
