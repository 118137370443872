import RouterMain from "./components/RouterMain";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline, GlobalStyles } from "@mui/material";
import Theme from "./Theme.jsx";
import { RecoilRoot } from "recoil";
import Authorized from "./components/Authorized";
import GetMerchantProfile from "./components/global/GetMerchantProfile";
import GetProfile from "./GetProfile";

function App() {
  return (
    <RecoilRoot>
      <GetProfile>
        <Authorized>
          <BrowserRouter>
            <Theme>
              <GlobalStyles
                styles={{ body: { backgroundColor: "#eceff1 !important" } }}
              />
              <CssBaseline />
              <GetMerchantProfile />
              <RouterMain />
            </Theme>
          </BrowserRouter>
        </Authorized>
      </GetProfile>
    </RecoilRoot>
  );
}

export default App;
