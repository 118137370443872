import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { tableCustomerList } from "../../states/customer-list";

function CustomerItem({ row }) {
  return (
    <TableRow>
      <TableBodyCell>
        {moment(row.dateCreated).format("DD/MMM/YYYY HH:mm")}
      </TableBodyCell>
      <TableBodyCell>{row.refCustomerId}</TableBodyCell>
      <TableBodyCell>{row.name}</TableBodyCell>
      <TableBodyCell>{row.bankName}</TableBodyCell>
      <TableBodyCell>{row.bookBankNumber}</TableBodyCell>
      <TableBodyCell>{row.bookBankName}</TableBodyCell>
      <TableBodyCell>{row.bookBankBranch}</TableBodyCell>
      {/* <TableBodyCell>{row.customerBranchName}</TableBodyCell> */}
    </TableRow>
  );
}

export default function TableCustomer() {
  const customerListState = useRecoilValue(tableCustomerList.customerList);

  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography fontSize={"1.5rem"}>Customer Detail</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Create At</TableHeadCell>
              <TableHeadCell>Ref Customer Id</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              {/* <TableHeadCell>Last Name</TableHeadCell> */}
              <TableHeadCell>Bank</TableHeadCell>
              <TableHeadCell>Book Bank Number</TableHeadCell>
              <TableHeadCell>Account Name</TableHeadCell>
              <TableHeadCell>Branch Name</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerListState &&
              customerListState.map((row) => {
                return <CustomerItem row={row} />;
              })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
