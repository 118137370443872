import { useEffect } from "react";
import { authState } from "../states/auth";
import { useRecoilState } from "recoil";

function Authorized({ children }) {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [authToken, setAuthToken] = useRecoilState(authState.authToken);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuthToken(localStorage.getItem("token"));
      setIsLogin(true);
    } else {
      setAuthToken(null);
      setIsLogin(false);
    }
  }, []);

  return children;
}

export default Authorized;
