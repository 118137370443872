import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FileUploader } from "./FileUploader";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker
} from "@mui/x-date-pickers";

import { useAPI } from "../../services/happypay-api";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useRecoilState } from "recoil";

import { dialogSubmitTransferSlipState } from "../../states/dialog/submit-transfer-slip";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as _ from "lodash";

const schema = yup.object({
  amount: yup
    .number()
    .required()
    .typeError("Invalid amount")
    .min(1, "Mininum ${min}"),
  currency: yup.string().trim().required(),
  link_refer: yup
    .string()
    .optional()
    .trim()
    .url("refer link must be formal url")
});

export default function DialogSubmitTransferSlip() {
  const api = useAPI();
  const [open, setOpen] = useRecoilState(dialogSubmitTransferSlipState.open);

  const form = useForm({
    defaultValues: {
      date: moment(),
      time: moment()
    },
    resolver: yupResolver(schema, {})
  });

  const onSubmit = (values) => {
    //form validator

    if (!_.isEmpty(form.formState.errors)) {
      alert(JSON.stringify(form.formState.errors));
      return;
    }

    const data = new FormData();

    data.append("amount", values.amount);
    data.append("currency", values.currency);
    data.append("date", values.date.format("YYYY-MM-DD"));
    data.append("time", values.time.format("HH:mm"));
    if (values.link_refer && values.link_refer !== "") {
      data.append("link_refer", values.link_refer);
    }
    data.append("file", values.file);

    api
      .uploadEvidenceTopup(data)
      .then(() => {
        form.reset();
        setOpen(false);
      })
      .catch(() => {})
      .finally();
  };

  const onClose = () => {
    form.reset();
    setOpen(false);
  };

  const onSelectFile = (event) => {
    form.setValue("file", event.target.files[0]);
  };

  return (
    <Dialog maxWidth={"sm"} fullWidth open={open}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography fontSize={"1.5rem"}>Upload topup slip</Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 5 }}>
            <Stack spacing={2}>
              <TextField
                label="Amount"
                value={form.watch("amount")}
                onChange={(event) =>
                  form.setValue("amount", event.currentTarget.value)
                }
                helperText={form.formState?.errors?.amount?.message}
              />
              <TextField
                label="Currency"
                value={form.watch("currency")}
                onChange={(event) =>
                  form.setValue("currency", event.currentTarget.value)
                }
                helperText={form.formState?.errors?.currency?.message}
              />
              <TextField
                label="Transfer reference url (Crypto)"
                value={form.watch("link_refer")}
                onChange={(event) =>
                  form.setValue("link_refer", event.currentTarget.value)
                }
                helperText={form.formState?.errors?.link_refer?.message}
              />

              <FileUploader onChange={onSelectFile} imageButton />
              <Box>
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={form.watch("date")}
                        onChange={(selectedDate) => {
                          form.setValue("date", selectedDate);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />{" "}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <TimePicker
                        value={form.watch("time")}
                        onChange={(selectedTime) => {
                          form.setValue("time", selectedTime);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />{" "}
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
