import { atom } from "recoil";

const alertMessage = atom({
  key: "login.alertMessage",
  default: null
});

export const loginState = {
  alertMessage
};
