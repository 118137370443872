import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from "@mui/material";
import dragonpayService, { useAPI } from "../services/happypay-api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { LoginButton } from "../Theme";

import dayjs from "dayjs";

function TokenPage() {
  const api = useAPI();
  const [tokenList, setTokenList] = useState([]);

  useEffect(() => {
    api
      .tokenList()
      .then((data) => {
        setTokenList(data);
      })
      .catch((error) => {
        setTokenList([]);
      });
  }, []);

  // const handleGenerate = () => {
  //   api
  //     .generateCredential()
  //     .then((data) => {
  //       setTokenList(data);
  //     })
  //     .catch((error) => {
  //       setTokenList([]);
  //     });
  // };

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h4">API Credential</Typography>
          </Grid>
          {/* <Grid item>
            <Button variant="contained" onClick={handleGenerate}>
              Renew Credential
            </Button>
          </Grid> */}
        </Grid>
      </Box>
      <Box>
        <Paper sx={{ p: 3 }}>
          {tokenList &&
            tokenList.length > 0 &&
            tokenList.map((token) => {
              return (
                <>
                  <Grid
                    container
                    alignItems={"center"}
                    mb={1.5}
                    key={token.clientId}
                  >
                    <Grid item sm={2}>
                      <Typography color="text.secondary" fontWeight="bold">
                        Client ID
                      </Typography>
                    </Grid>
                    <Grid item sm={5}>
                      <TextField
                        fullWidth
                        value={token.clientId}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <ContentCopyIcon
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      token.clientId
                                    );
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems={"center"} mb={1.5}>
                    <Grid item sm={2}>
                      <Typography color="text.secondary" fontWeight="bold">
                        Client Secret
                      </Typography>
                    </Grid>
                    <Grid item sm={7}>
                      <TextField
                        fullWidth
                        value={token.clientSecret}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <ContentCopyIcon
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      token.clientSecret
                                    );
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems={"center"} mb={1.5}>
                    <Grid item sm={2}>
                      <Typography color="text.secondary" fontWeight="bold">
                        Created Date
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {dayjs(token?.createdDate).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}{" "}
                        (n days ago)
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Paper>
      </Box>
    </Stack>
  );
}
export default TokenPage;
