import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
  Grid,
  Container
} from "@mui/material";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { decimalFourFormat } from "../utils/formats.js";

import SearchWithdrawals from "../components/cusWithdrawals/SearchWithdrawals";
import TableData from "../components/cusWithdrawals/TableData";
import { useRecoilState } from "recoil";
import { useAPI } from "../services/happypay-api";
import {
  withdrawFilterStatusState,
  withdrawFilterStartState,
  withdrawFilterEndState,
  withdrawListState,
  withdrawSumState,
  withdrawSumFeeState
} from "../states/withdraw-list";
import { authState } from "../states/auth";
import dayjs from "dayjs";
import WithdrawBalanceStat from "../components/cusWithdrawals/WithdrawBalanceStat";
import { withdrawState } from "../states/withdraw";

function WithdrawPage() {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [withdrawFilterStatus, setwithdrawFilterStatus] = useRecoilState(
    withdrawFilterStatusState
  );
  const [withdrawFilterStart, setwithdrawFilterStart] = useRecoilState(
    withdrawFilterStartState
  );
  const [withdrawFilterEnd, setwithdrawFilterEnd] = useRecoilState(
    withdrawFilterEndState
  );
  const api = useAPI();

  const [withdrawSum, setWithdrawSum] = useRecoilState(withdrawSumState);
  const [withdrawSumFee, setWithdrawSumFee] =
    useRecoilState(withdrawSumFeeState);
  const [withdrawList, setWithdrawList] = useRecoilState(withdrawListState);

  const [totalBalance, setTotalBalance] = useRecoilState(
    withdrawState.totalBalance
  );
  const [pendingWithdraw, setPendingWithdraw] = useRecoilState(
    withdrawState.pendingWithdraw
  );
  const [withdrawAvailable, setWithdrawAvailable] = useRecoilState(
    withdrawState.withdrawAvailable
  );
  const [pendingTopup, setPendingTopup] = useRecoilState(
    withdrawState.pendingTopup
  );

  useEffect(() => {
    callWithdrawList(
      withdrawFilterStatus,
      withdrawFilterStart,
      withdrawFilterEnd
    );
  }, []);

  useEffect(() => {
    callWithdrawList(
      withdrawFilterStatus,
      withdrawFilterStart,
      withdrawFilterEnd
    );

    // dragonpayService
    //   .sumDepositWithdraw(withdrawFilterStart, withdrawFilterEnd)
    //   .then(({ data }) => {
    //     // console.log('data',data)
    //     setWithdrawSum(data.result.withdraw);
    //     setWithdrawSumFee(data.result.withdrawFee)
    //     // setDepositList(data.result);
    //   })
    //   .catch((error) => {
    //     // setDepositList([]);
    //   });
  }, [withdrawFilterStatus, withdrawFilterStart, withdrawFilterEnd]);

  useEffect(() => {
    api
      .balanceMerchant(localStorage.getItem("token"))
      .then((data) => {
        setWithdrawAvailable(data.balanceWithdraw);
      })
      .catch((error) => {});
  }, [api]);

  const callWithdrawList = (
    withdrawFilterStatus,
    withdrawFilterStart,
    withdrawFilterEnd
  ) => {
    api
      .withdrawList(
        withdrawFilterStatus,
        withdrawFilterStart && dayjs(withdrawFilterStart).format("YYYY-MM-DD"),
        withdrawFilterEnd && dayjs(withdrawFilterEnd).format("YYYY-MM-DD")
      )
      .then((data) => {
        setWithdrawList(data);
      })
      .catch((error) => {
        const { message, statusCode } = error;
        alert(message);
      });
  };

  const exportCsv_old = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: `Report Withdraw ${localStorage.getItem("name")} ${dayjs(withdrawFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(withdrawFilterEnd).format("DD-MM-YYYY")}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Report Withdraw ${localStorage.getItem("name")} ${dayjs(withdrawFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(withdrawFilterEnd).format("DD-MM-YYYY")}`
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    let obj = withdrawList.map((e) => {
      console.log(e)
      return {
        time: moment(e.dateCreated).format("DD/MM/YYYY HH:mm"),
        // customer_name: e.customerName,
        merchant_ref_no: e.clientTransactionId,
        amount: decimalFourFormat(e.amount),
        "fee %": e.totalFee,
        fee: decimalFourFormat(e.feeOutAmount),
        "vat 7 %": (e.feeOutAmount*0.07).toFixed(4),
        amountWithFee: decimalFourFormat(e.amountWithFee.toFixed(4)),
        beforeBalance: decimalFourFormat(e.balanceBefore.toFixed(4)),
        afterBalance: decimalFourFormat(e.balanceAfter.toFixed(4)),
        status: e.status
      };
    });

    csvExporter.generateCsv(obj);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h4" component="h2">
            Withdraw
          </Typography>
        </Grid>

        <Grid item sm={12} xs={12} md={12}>
          <WithdrawBalanceStat />
        </Grid>

        <Grid item sm={12} xs={12}>
          <SearchWithdrawals exportCsv={exportCsv_old}/>
        </Grid>
        {/* <Grid item xs={12}>
          <AllTotalData />
        </Grid> */}
        <Grid item sm={12} xs={12}>
          <TableData />
        </Grid>
      </Grid>
    </>
  );
}
export default WithdrawPage;
