import { atom } from "recoil";

const totalBalance = atom({
  key: "withdraw.totalBalance",
  default: 0
});

const withdrawAvailable = atom({
  key: "withdraw.withdrawAvailable",
  default: 0
});

const pendingWithdraw = atom({
  key: "withdraw.pendingWithdraw",
  default: 0
});

const pendingTopup = atom({
  key: "withdraw.pendingTopup",
  default: 0
});

export const withdrawState = {
  totalBalance,
  withdrawAvailable,
  pendingWithdraw,
  pendingTopup
};
