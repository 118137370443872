import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MainDashboard from "../pages/MainDashboard";
import WithdrawPage from "../pages/WithdrawPage";
import DepositPage from "../pages/DepositPage";
import SettingPage from "../pages/Setting";
import Register from "../pages/Register";
import Login from "../pages/Login";
import LayoutMain from "../layout/LayoutMain";
import TokenPage from "../pages/TokenPage";
import CustomerPage from "../pages/CustomerPage";
import ForgotPassword from "../pages/ForgetPassword";
import { authState } from "../states/auth";
import { useRecoilValue } from "recoil";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import Topups from "../pages/Topups";
import Settle from "../pages/Settle";
import { Typography } from "@mui/material";

function RequiredAuth({ children }) {
  const isLogin = useRecoilValue(authState.isLogin);
  useEffect(() => {
    if (isLogin === false) {
      window.location = "/login";
      return;
    }
  }, [isLogin]);

  return isLogin && children;
}

function PassAuth({ children }) {
  const isLogin = useRecoilValue(authState.isLogin);
  useEffect(() => {
    if (isLogin === true) {
      window.location = "/";
      return;
    }
  }, [isLogin]);

  if (isLogin === null) {
    return null;
  }

  return !isLogin && children;
}

function RouterMain() {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <PassAuth>
            <Login />
          </PassAuth>
        }
      />
      <Route
        path="register"
        element={
          <PassAuth>
            <Register />
          </PassAuth>
        }
      />
      <Route
        path="forgot-password"
        element={
          <PassAuth>
            <ForgotPassword />
          </PassAuth>
        }
      />
      <Route
        path="reset-password"
        element={
          <PassAuth>
            <ResetPasswordPage />
          </PassAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequiredAuth>
            <LayoutMain />
          </RequiredAuth>
        }
      >
        <Route index element={<MainDashboard />} />
        <Route path="dashboard" element={<MainDashboard />} />
        <Route path="deposit" element={<DepositPage />} />
        <Route path="withdraw" element={<WithdrawPage />} />
        <Route path="topups" element={<Topups />} />
        <Route path="settle" element={<Settle />} />
        <Route path="settings" element={<SettingPage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />
        <Route path="credential" element={<TokenPage />} />
        <Route path="customer" element={<CustomerPage />} />
      </Route>
      <Route
        path="*"
        element={
          <PassAuth>
            <Typography textAlign={"center"} sx={{ my: 2 }}>
              Page not found
            </Typography>
          </PassAuth>
        }
      />
    </Routes>
  );
}

export default RouterMain;
