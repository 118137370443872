import { atom } from "recoil";

const open = atom({
  key: "dialogRequestTopup.open",
  default: false
});

const amount = atom({
  key: "dialogRequestTopup.amount",
  default: ""
});

const currency = atom({
  key: "dialogRequestTopup.currency",
  default: ""
});

const responseStatus = atom({
  key: "dialogRequestTopup.responseStatus",
  default: null
});

const withdrawBalance = atom({
  key: "dialogRequestTopup.withdrawBalance",
  default: 0
});

export const dialogRequestTopupState = {
  open,
  amount,
  currency,
  responseStatus,
  withdrawBalance
};
