import React, { useEffect, useState } from "react";
import { Typography, Grid, Table, Tabs, Tab, Box, Stack } from "@mui/material";
import TableCustomer from "../components/customer/TableCustomer";
import api, { useAPI } from "../services/happypay-api";
import { useRecoilState } from "recoil";
import { tableCustomerList } from "../states/customer-list";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return <>{value === index && children}</>;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function CustomerPage() {
  const [selectTab, setSelectTab] = useState(0);
  const api = useAPI();

  const [customerListState, setTableCustomerList] = useRecoilState(
    tableCustomerList.customerList
  );


  useEffect(() => {
    api
      .getCustomer()
      .then((data) => {
        setTableCustomerList(data);
      })
      .catch();
  }, []);


  const handleChange = (event, value) => {
    setSelectTab(value);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Customer
        </Typography>
      </Box>
      <Box>
        <TableCustomer />
      </Box>
    </Stack>
  );
}
export default CustomerPage;
