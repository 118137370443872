import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  Paper,
  Box
} from "@mui/material";
import { useAPI } from "../services/happypay-api";

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const api = useAPI();

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    // dragonpayService
    //   .profileMerchant()
    //   .then(({ data }) => {
    //     const { callback, statusCode, name, minimum_balance } = data;
    //     if (!statusCode) {
    //       setCallbackUrl(callback);
    //       setName(name);
    //       setMinimumBalance(minimum_balance);
    //       // setPassword(result.password)
    //       // setSecretKey(result.secret_key)
    //       // handleClose()
    //     }
    //   })
    //   .catch((error) => {
    //     const { message } = error.response.data;
    //     alert(message);
    //     //   console.log(error);
    //   });
  }, []);

  const onSubmit = () => {
    if (newPassword && confirmNewPassword) {
      if (newPassword.length < 6) {
        alert("please check data");
      }
      if (confirmNewPassword != newPassword) {
        alert("please check data");
      } else {
        api
          .changePassword(newPassword, confirmNewPassword)
          .then((data) => {
            const { status } = data;
            if (status) {
              alert("success");
            }
          })
          .catch((error) => {
            const { message } = error;
            alert(message);
          });
      }
    } else {
      alert("please check data");
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Change password
        </Typography>
      </Box>
      <Paper sx={{ p: 3, py: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="New password"
                value={newPassword}
                onChange={handleChange(setNewPassword)}
              />
              <TextField
                fullWidth
                label="Confirm new password"
                value={confirmNewPassword}
                onChange={handleChange(setConfirmNewPassword)}
              />
              <Box display={"flex"} justifyContent={"end"}>
                <Button variant="contained" onClick={onSubmit}>
                  Save
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};
export default ChangePasswordPage;
