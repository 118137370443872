import React, { useState } from "react";
import {
  Paper,
  Stack,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Button
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

import { useRecoilState } from "recoil";
import {
  withdrawFilterStatusState,
  withdrawFilterStartState,
  withdrawFilterEndState
} from "../../states/withdraw-list";
import DialogCusWithdraw from "./DialogCusWithdraw.jsx";
import CustomerContext from "../../contexts/CustomerContext.jsx";

function SearchWithdrawals(props) {
  const [withdrawFilterStatus, setwithdrawFilterStatus] = useRecoilState(
    withdrawFilterStatusState
  );
  const [withdrawFilterStart, setwithdrawFilterStart] = useRecoilState(
    withdrawFilterStartState
  );
  const [withdrawFilterEnd, setwithdrawFilterEnd] = useRecoilState(
    withdrawFilterEndState
  );

  const handleChangeStartDate = (startValue, a) => {
    setwithdrawFilterStart(startValue.format("YYYY-MM-DD"));
  };

  const handleChangeEndDate = (endValue) => {
    setwithdrawFilterEnd(endValue.format("YYYY-MM-DD"));
  };

  const handleChange = (event) => {
    setwithdrawFilterStatus(event.target.value);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <React.Fragment>
        <CustomerContext.Provider
          value={{
            openDialog,
            setOpenDialog
          }}
        >
          <Paper sx={{ p: 3 }} elevation={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2} sm={12} xs={12}>
                <Grid container item sm={10} xs={12} md={10}>
                  <Grid item sm={4} xs={4} md={10}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 2, sm: 2, md: 3 }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small">Status</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={withdrawFilterStatus}
                          label="Status"
                          onChange={handleChange}
                        >
                          {/* <MenuItem value={5}>All</MenuItem> */}
                          <MenuItem value={0}>All</MenuItem>
                          <MenuItem value={1}>Success</MenuItem>
                          <MenuItem value={2}>Pending</MenuItem>
                          <MenuItem value={3}>Failed</MenuItem>
                          {/* <MenuItem value={4}>Holded</MenuItem> */}
                        </Select>
                      </FormControl>

                      <DesktopDatePicker
                        label="Start"
                        inputFormat="DD/MM/YYYY"
                        value={withdrawFilterStart}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                      <DesktopDatePicker
                        label="End"
                        inputFormat="DD/MM/YYYY"
                        value={withdrawFilterEnd}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container sm={2} xs={4} md={2} mt={2}>
                  {/* <Grid item sm={2}> */}
                    <Box>
                      <Button
                        onClick={props.exportCsv}
                        variant="contained"
                        fullWidth
                        sx={{ height: "100%" }}
                        startIcon={<ImportExportIcon />}
                      >
                        Export
                      </Button>
                    </Box>
                  {/* </Grid> */}
                  {/* <Grid container justifyContent="center" alignItems="center" onClick={handleClickOpen}>
                    <ColorGreenButton variant="contained">
                      Withdraw
                    </ColorGreenButton>
                  </Grid> */}
                </Grid>
                {/* <Grid item sm={4}>
              <TextField fullWidth placeholder="Search withdraw item"/>
            </Grid> */}
              </Grid>
            </LocalizationProvider>
          </Paper>
          <DialogCusWithdraw />
        </CustomerContext.Provider>
      </React.Fragment>
    </>
  );
}
export default SearchWithdrawals;
