import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  useMediaQuery,
  Divider,
  TextField,
  Button,
  Link as MLink,
  Alert,
  Icon
} from "@mui/material";
import { useAPI } from "../services/happypay-api";

//theme
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import logo from "../assets/logo/logo.svg";
import SingleBoxLayout from "../layout/SingleBoxLayout";

import { forgotPasswordState } from "../states/forgot-password";
import { useForm } from "react-hook-form";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function ForgotPassword() {
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);
  const [errorMessage, setErrorMessage] = useRecoilState(
    forgotPasswordState.errorMessage
  );
  const [isSubmitted, setIsSubmitted] = useRecoilState(
    forgotPasswordState.isSubmitted
  );
  const api = useAPI();

  const { handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      username: ""
    }
  });

  const onSubmit = (values) => {
    api
      .recoveryPassword(values.username)
      .then((data) => {
        const { statusCode, message } = data;
        if (!statusCode) {
          setErrorMessage(null);
          reset();
          setIsSubmitted(true);
        } else {
          setErrorMessage(message);
        }
      })
      .catch((error) => {
        const { message } = error;
        setErrorMessage(message);
      });

    return true;
  };

  useEffect(() => {
    return () => {
      setIsSubmitted(false);
    };
  }, []);

  return (
    <SingleBoxLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={logo} sx={{ width: 120 }} alt="logo" />
        </Box>
        <Divider sx={{ mt: 2, mb: 4 }} />
        {isSubmitted && (
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem"
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              color={"#444"}
              sx={{ my: 2, display: "flex", alignItems: "center" }}
            >
              <CheckCircleIcon color="success" sx={{ mr: 1 }} /> Reset password
              link has been sent to your email
            </Typography>
          </Box>
        )}
        {isSubmitted === false && (
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem"
              }}
            >
              Forgot Password
            </Typography>
            {errorMessage && (
              <Box sx={{ my: 1 }}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
            <Typography color={"#444"} sx={{ mb: 3 }}>
              Input your email account to reset password
            </Typography>
            <Stack spacing={2} direction={"column"}>
              <TextField
                fullWidth
                label="Email"
                value={watch("username")}
                onChange={(e) => setValue("username", e.target.value)}
              />
              <Button variant="contained" type="submit">
                Reset Password
              </Button>
            </Stack>
          </Box>
        )}
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Box>
          <Typography textAlign={"center"}>
            Back to <Link to="/login">Login</Link>
          </Typography>
        </Box>
      </form>
    </SingleBoxLayout>
  );
}

export default ForgotPassword;
