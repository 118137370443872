import { useEffect } from "react";
import { useAPI } from "./services/happypay-api";

export default function GetProfile({ children }) {
//   const api = useAPI();

//   useEffect(() => {
//     api
//       .profileMerchant()
//       .then(() => {})
//       .catch(() => {});
//   }, [api]);

  return children;
}
