import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Theme({ children }) {
  const mdTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            padding: "10px 30px"
          }
        }
      }
    },
    typography: {
      button: {
        textTransform: "none"
      }
    },
    palette: {
      mode: "light",
      primary: {
        main: "#280631"
      },
      secondary: {
        main: "#aa466c"
      },
      error: {
        main: "#a40808"
      },
      warning: {
        main: "#cea725"
      },
      success: {
        main: "#177b1a"
      },
      close: {
        main: "#999"
      },
      divider: "rgba(49,47,47,0.12)"
    }
  });

  return (
    <ThemeProvider theme={mdTheme}>
      {children}
    </ThemeProvider>
  );
}
export default Theme;
