import React, { useEffect, useState } from "react";
import { Typography, Grid, Table, Tabs, Tab, Box, Stack } from "@mui/material";
import WithdrawBalanceStat from "../components/topups/WithdrawBalanceStat";
import TableRequestTopups from "../components/topups/TableSettle";
import Payslip from "../components/topups/Payslip";
import DialogRequestTopup from "../components/topups/DialogRequestTopup";
import api, { useAPI } from "../services/happypay-api";
import { useRecoilState } from "recoil";
import { tableRequestTopupsState } from "../states/table-request-topups";
import PayslipHistory from "../components/topups/PayslipHistory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return <>{value === index && children}</>;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function Topups() {
  const [selectTab, setSelectTab] = useState(0);
  const api = useAPI();

  const [requestTopupsList, setRequestTopupsList] = useRecoilState(
    tableRequestTopupsState.requestTopupsList
  );
  const [requestTopupsPendingList, setRequestTopupsPendingList] =
    useRecoilState(tableRequestTopupsState.requestTopupsPendingList);

  useEffect(() => {
    api
      .getAllRequestTopups("pending")
      .then((data) => {
        setRequestTopupsPendingList(data);
      })
      .catch();
  }, []);

  useEffect(() => {
    api
      .getAllRequestTopups("approved,rejected")
      .then((data) => {
        setRequestTopupsList(data);
      })
      .catch();
  }, []);

  const handleChange = (event, value) => {
    setSelectTab(value);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Topup
        </Typography>
      </Box>
      <Box>
        <WithdrawBalanceStat />
      </Box>
      <Box>
        <Payslip />
      </Box>
      <Box>
        <PayslipHistory />
      </Box>
    </Stack>
  );
}
export default Topups;
