import { TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { depositListState } from "../../states/deposit-list";

export default function SearchInPendingDepositForm() {
  const [inputSearchPendingDeposit, setInputSearchPendingDeposit] =
    useRecoilState(depositListState.inputSearchPendingDeposit);

  const handleOnChange = (e) => {
    setInputSearchPendingDeposit(e.target.value);
  };

  return (
    <>
      <TextField
        placeholder="Search"
        onChange={handleOnChange}
        value={inputSearchPendingDeposit}
        onFocus={(e) => {
          e.target.parentNode.setAttribute("style", "width:300px");
        }}
        onBlur={(e) => {
          e.target.parentNode.setAttribute("style", "width:200px");
        }}
      />
    </>
  );
}
