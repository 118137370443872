import { atom } from "recoil";

const errorMessage = atom({
  key: "forgotPassword.errorMessage",
  default: null
});

const isSubmitted = atom({
  key: "forgotPassword.isSubmitted",
  default: false
});

export const forgotPasswordState = {
  errorMessage,
  isSubmitted
};
