import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { dialogRequestTopupState } from "../../states/dialog/request.topup";
import { useRecoilState } from "recoil";
import { tableRequestTopupsState } from "../../states/table-request-topups";
import moment from "moment";

import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";

import ChipPending from "../commons/ChipPending";
import ChipApprove from "../commons/ChipApprove";
import ChipReject from "../commons/ChipReject";

export default function TableSettleHistory() {
  const [openRequestTopup, setOpenRequestTopup] = useRecoilState(
    dialogRequestTopupState.open
  );

  const [requestTopupsList, setRequestTopupsList] = useRecoilState(
    tableRequestTopupsState.requestTopupsList
  );

  const [requestTopupsPendingList, setRequestTopupsPendingList] =
    useRecoilState(tableRequestTopupsState.requestTopupsPendingList);

  const handleOpenRequestToup = () => {
    setOpenRequestTopup(true);
  };
  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography fontSize={"1.5rem"}>Settle History</Typography>
          </Grid>
          <Grid item>
            <Stack direction={"row"} spacing={2}>
              <Box sx={{ width: { sm: 200 } }}>
                <Select fullWidth value="">
                  <MenuItem value="">Filter</MenuItem>
                  <MenuItem value="test">Test</MenuItem>
                </Select>
              </Box>
              <Box>
                <TextField placeholder="Search" />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Time</TableHeadCell>
                <TableHeadCell>Amount</TableHeadCell>
                <TableHeadCell>Currency</TableHeadCell>
                <TableHeadCell>Status</TableHeadCell>
                <TableHeadCell>Respond Date</TableHeadCell>
                <TableHeadCell>Remark</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestTopupsList &&
                requestTopupsList.length > 0 &&
                requestTopupsList.map((row) => {
                  return (
                    <TableRow>
                      <TableBodyCell>
                        {moment(row.dateCreated).format("DD/MMM/YYYY HH:mm")}
                      </TableBodyCell>
                      <TableBodyCell>
                        {row.amount.toLocaleString('en-US')}
                      </TableBodyCell>
                      <TableBodyCell>THB</TableBodyCell>
                      <TableBodyCell>
                        {row.status === "approved" && <ChipApprove />}
                        {row.status === "rejected" && <ChipReject />}
                      </TableBodyCell>
                      <TableBodyCell>
                        {row.dateRespond &&
                          moment(row.dateRespond).format("DD/MMM/YYYY HH:mm")}
                      </TableBodyCell>
                      <TableBodyCell>{row.remark || "-"}</TableBodyCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={100}
            page={0}
            onPageChange={() => {}}
            rowsPerPage={10}
            onRowsPerPageChange={() => {}}
            rowsPerPageOptions={[]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
