import React, { useContext } from "react";
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Box,
    Paper,
    Button,
    InputLabel,
    OutlinedInput,
    FormControl,
    Alert,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

//formats
import { decimalTwoFormat } from "../../utils/formats.js";
import { useRecoilState } from "recoil";
import { depositListState } from "../../states/deposit-list.js";

function DepositDialog(props) {
    const [openQrcodeDialog, setOpenQrcodeDialog] = useRecoilState(
        depositListState.openQrcodeDialog
    );

    const [inputAmountDeposit, setInputAmountDeposit] = useRecoilState(
        depositListState.inputAmountDeposit
    );
    const [qrcodeDeposit, setQrCodeDeposit] = useRecoilState(depositListState.qrCodeDeposit)
    const [refNodeposit, setRefNoDeposit] = useRecoilState(depositListState.refNoDeposit)
    const [merchantRefNo, setMerchantRefNo] = useRecoilState(depositListState.merchantRefNo)
    const [statusQr,setStatusQr] = useRecoilState(depositListState.statusQr)
    const [amountGenQr,] = useRecoilState(depositListState.amountGenQr)

    const handleClose = () => {
        props.handleClickOpen()
        setQrCodeDeposit("")
        setRefNoDeposit("")
        setMerchantRefNo("")
        setInputAmountDeposit(0)
        // setOpenQrcodeDialog(false);
        setStatusQr(false)
       
    };

    const handleChange = (setValue) => (event) => {
        setValue(event.target.value);
    };

    const onSubmit = () => {
        props.genQr()
    }

    //mock data
    let Refno = "00000126";
    let time = "Oct 17 ,2022, 2:23 AM";
    let status = "Success";
    let type = "Deposits";
    let merchant = "qrs";
    let customer = "-";
    let amount = 20000;
    let fee = 600;
    let vat = 42;
    let amountNet = 19358;
    let balance = 19358;
    let remark = "-";

    return (
        <div>
            <Dialog open={openQrcodeDialog} onClose={handleClose}>
                <Box maxWidth={500}>
                    <Paper sx={{ p: 2 }}>
                        <DialogTitle textAlign={"left"}>Generate QR CODE for deposit</DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                            // rowSpacing={1.5}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        Amount
                                    </Typography>
                                </Grid>


                                <Grid
                                    item
                                    xs={6}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        // color="inputLogin"
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="outlined-adornment-username">
                                            Amount
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-username fullWidth"
                                            label="Username"
                                            inputProps={type = "number"}
                                            type="number"
                                            value={inputAmountDeposit}
                                            onChange={handleChange(setInputAmountDeposit)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    padding={'5px'}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Button variant="contained" onClick={onSubmit}>Submit</Button>
                                </Grid>
                                {qrcodeDeposit ?
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <img src={"data:image/jpeg;base64," + qrcodeDeposit} />

                                        </Grid>
                                        {statusQr ?
                                            <Grid
                                                item
                                                xs={12}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                                    Transaction is PAID
                                                </Alert>

                                            </Grid>
                                            : null}

                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                Amount
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                {decimalTwoFormat(amountGenQr)}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                Ref No.
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                {refNodeposit}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                Tx Merchant.
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={14}
                                                fontWeight="bold"
                                                color="#79919D"
                                            >
                                                {merchantRefNo}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    : null

                                }






                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Paper>
                </Box>
            </Dialog>
        </div >
    );
}

export default DepositDialog;
