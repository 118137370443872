import { atom } from "recoil";

const open = atom({
  key: "dialogSubmitTransferSlip.open",
  default: false
});

const amount = atom({
  key: "dialogSubmitTransferSlip.amount",
  default: ""
});

const currency = atom({
  key: "dialogSubmitTransferSlip.currency",
  default: ""
});

const responseStatus = atom({
  key: "dialogSubmitTransferSlip.responseStatus",
  default: null
});

export const dialogSubmitTransferSlipState = {
  open,
  amount,
  currency,
  responseStatus
};
