import {
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { withdrawState } from "../../states/withdraw";
import CardAmount from "../cards/Amount";

export default function WithdrawBalanceStat() {
  const totalBalance = useRecoilValue(withdrawState.totalBalance);
  const pendingWithdraw = useRecoilValue(withdrawState.pendingWithdraw);
  const withdrawAvailable = useRecoilValue(withdrawState.withdrawAvailable);
  const pendingTopup = useRecoilValue(withdrawState.pendingTopup);

  return (
    <Grid container spacing={2}>
      {/* <Grid item sm={3}>
        <Card>
          <CardContent>
            <Typography>Total Withdraw</Typography>
            <Typography textAlign={"right"} fontSize={"1.5rem"} sx={{ pt: 2 }}>
              {totalBalance}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={3}>
        <Card>
          <CardContent>
            <Typography>Withdraw Available</Typography>
            <Typography textAlign={"right"} fontSize={"1.5rem"} sx={{ pt: 2 }}>
              {withdrawAvailable}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={3}>
        <Card>
          <CardContent>
            <Typography>Pending Withdraw</Typography>
            <Typography textAlign={"right"} fontSize={"1.5rem"} sx={{ pt: 2 }}>
              {pendingWithdraw}
            </Typography>
          </CardContent>
        </Card>
      </Grid> */}
      <Grid item sm={3}>
        <Card>
          <CardAmount
            label={"Pending Topup"}
            amount={pendingTopup && pendingTopup.toLocaleString('en-US')}
          />
          {/* <CardContent>
            <Typography>Pending Topup</Typography>
            <Typography textAlign={"right"} fontSize={"1.5rem"} sx={{ pt: 2 }}>
              {pendingTopup}
            </Typography>
          </CardContent> */}
        </Card>
      </Grid>
    </Grid>
  );
}
