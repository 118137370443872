import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { dialogRequestTopupState } from "../../states/dialog/request.topup";
import { useRecoilState } from "recoil";
import { tableRequestTopupsState } from "../../states/table-request-topups";
import moment from "moment";

import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";

import ChipPending from "../commons/ChipPending";
import ChipApprove from "../commons/ChipApprove";
import ChipReject from "../commons/ChipReject";

export default function TableSettle() {
  const [openRequestTopup, setOpenRequestTopup] = useRecoilState(
    dialogRequestTopupState.open
  );

  const [requestTopupsList, setRequestTopupsList] = useRecoilState(
    tableRequestTopupsState.requestTopupsList
  );

  const [requestTopupsPendingList, setRequestTopupsPendingList] =
    useRecoilState(tableRequestTopupsState.requestTopupsPendingList);

  const handleOpenRequestToup = () => {
    setOpenRequestTopup(true);
  };
  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography fontSize={"1.5rem"}>Settle Pending</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleOpenRequestToup}>
              Request to settle
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ width: 200 }}>Time</TableHeadCell>
              <TableHeadCell>Amount</TableHeadCell>
              <TableHeadCell>Currency</TableHeadCell>
              <TableHeadCell sx={{ width: 0 }}>Status</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestTopupsPendingList &&
              requestTopupsPendingList.length > 0 &&
              requestTopupsPendingList.map((row) => {
                return (
                  <TableRow>
                    <TableBodyCell>
                      {moment(row.date_created).format("DD/MMM/YYYY HH:mm")}
                    </TableBodyCell>
                    <TableBodyCell>{row.amount.toLocaleString('en-US')}</TableBodyCell>
                    <TableBodyCell>THB</TableBodyCell>
                    <TableBodyCell>
                      <ChipPending />
                    </TableBodyCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
