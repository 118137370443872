import React from "react";
import { Box, FormLabel, Input, Typography } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const FileUploader = ({
  accept,
  imageButton = false,
  hoverLabel = "Click or drag to upload file",
  dropLabel = "Drop file here",
  width = "100%",
  height = "100px",
  backgroundColor = "#fff",
  image: {
    imageStyle = {
      height: "inherit"
    }
  } = {},
  onChange,
  onDrop
}) => {
  // const classes = useStyle();
  const [imageUrl, setImageUrl] = React.useState("");
  const [labelText, setLabelText] = React.useState(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (imageButton && e.dataTransfer.files[0]) {
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
    }
  };

  const handleChange = (event) => {
    console.log(event);
    if (imageButton && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
    }

    onChange(event);
  };

  return (
    <Box sx={{ p: 3, border: "solid 1px #eee" }}>
      <Input
        onChange={handleChange}
        accept={accept}
        sx={{ display: "none" }}
        id="file-upload"
        type="file"
      />
      <FormLabel
        htmlFor="file-upload"
        {...dragEvents}
        sx={{
          cursor: "pointer",
          textAlign: "center",
          display: "flex",
          "&:hover p,&:hover svg,& img": {
            opacity: 1
          },
          "& p, svg": {
            opacity: 0.4
          },
          "&:hover img": {
            opacity: 0.3
          },
          ...(isDragOver && {
            "& img": {
              opacity: 0.3
            },
            "& p, svg": {
              opacity: 1
            }
          })
        }}
      >
        <Box
          width={width}
          height={height}
          bgcolor={backgroundColor}
          sx={{ pointerEvents: "none" }}
        >
          {imageButton && (
            <Box position="absolute" height={height} width={width}>
              <img alt="file upload" src={imageUrl} style={imageStyle} />
            </Box>
          )}

          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box
                height={height}
                width={width}
                sm={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute"
                }}
              >
                <CloudUploadIcon fontSize="large" />
                <Typography>{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </FormLabel>
    </Box>
  );
};
