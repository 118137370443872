import React, { useRef, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  InputAdornment,
  IconButton,
  Divider,
  TextField,
  Button,
  Link as MLink,
  Alert
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAPI } from "../services/happypay-api";

//theme
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import { loginState } from "../states/login";
import logo from "../assets/logo/logo.svg";
import SingleBoxLayout from "../layout/SingleBoxLayout";
import { useForm } from "react-hook-form";

function Login() {
  const api = useAPI();
  const [, setIsLogin] = useRecoilState(authState.isLogin);
  const [alertMessage, setAlertMessage] = useRecoilState(
    loginState.alertMessage
  );
  const { setValue, watch, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      username: "",
      password: ""
    }
  });
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef();

  const requestProfile = () => {
    api
      .profileMerchant(localStorage.getItem("token"))
      .then((data) => {
        const { name, statusCode } = data;
        if (!statusCode) {
          localStorage.setItem("name", name);
          setIsLogin(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (values) => {
    setAlertMessage(null);
    api
      .loginMerchant(values.username, values.password)
      .then((data) => {
        const { accessToken } = data;
        reset();
        localStorage.setItem("token", accessToken);
        requestProfile();
      })
      .catch((error) => {
        const { message } = error;
        setAlertMessage(message);
      })
      .finally(() => {});

    return true;
  };

  const handleClickShowPassword = () => {
    const catchPassword = getValues("password");
    setShowPassword(!showPassword);
    setValue("password", "");
    passwordRef.current.focus();
    setValue("password", catchPassword);
  };

  const handleChange = (field) => {
    return (event) => {
      setValue(field, event.target.value);
    };
  };

  return (
    <SingleBoxLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={logo} sx={{ width: 120 }} alt="logo" />
          </Box>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Box>
            <Typography
              color={"#222"}
              sx={{
                fontSize: "1.5rem",
                mb: 2,
                textAlign: "center"
              }}
            >
              Merchant Login
            </Typography>
            {alertMessage && (
              <Box sx={{ mb: 3 }}>
                <Alert severity="error">{alertMessage}</Alert>
              </Box>
            )}
            <Stack spacing={2} direction={"column"}>
              <TextField
                fullWidth
                label="Username"
                value={watch("username")}
                onChange={handleChange("username")}
              />
              <TextField
                fullWidth
                label="Password"
                inputRef={passwordRef}
                type={showPassword ? "text" : "password"}
                value={watch("password")}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button variant="contained" type="submit">
                Log In
              </Button>
            </Stack>
          </Box>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Box>
            <Typography textAlign={"center"}>
              <Link to="/forgot-password">Reset password</Link>
            </Typography>
          </Box>
        </Box>
      </form>
    </SingleBoxLayout>
  );
}

export default Login;
