import { atom } from "recoil";

const isLogin = atom({
  key: "auth.isLogin",
  default: null
});

const authToken = atom({
  key: "auth.token",
  default: null
});

const profile = atom({
  key: "auth.profile",
  default: null
});

export const authState = {
  isLogin,
  authToken,
  profile
};
