import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Divider
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { openDialogState, withdrawListState } from "../../states/withdraw-list";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import bank from "../../constance/bank.json";

//formats
import { decimalTwoFormat } from "../../utils/formats.js";
import ChipSuccess from "../commons/ChipSuccess";
import ChipPending from "../commons/ChipPending";
import ChipFail from "../commons/ChipFail";
import ChipHold from "../commons/ChipHold";

// function createData(time, refno, type, merchant, customer, amount, balance, status, remark) {
//     const feeRate = 3;
//     const vatRate = 7;
//     const fee = amount * (feeRate / 100);
//     const vat = fee * (vatRate / 100);
//     const amountNet = amount - (fee + vat);
//     return { time, refno, type, merchant, customer, amount, fee, feeRate, vat, vatRate, amountNet, balance, status, remark };
// }

// const rows = [
//     createData('Oct 16 ,2022, 2:23 AM', 'B000000051', 'Withdraw', 'qrs', 'Daenerys Targaryen', 30000, 29037, "Success", "-"),
//     createData('Oct 17 ,2022, 3:23 AM', 'B000000061', 'Withdraw', 'qrs', 'Melisandre', 9200, 8904.68, "Success", "-"),
//     createData('Oct 18 ,2022, 4:23 AM', 'B000000071', 'Withdraw', 'qrs', 'Ferrara Clifford', 57000, 55170.30, "Success", "-"),
//     createData('Oct 19 ,2022, 5:23 AM', 'B000000081', 'Withdraw', 'qrs', 'Rossini Frances', 30000, 29037, "Success", "-"),
//     createData('Oct 14 ,2022, 2:43 AM', 'B000000031', 'Withdraw', 'qrs', 'United States', 30000, 29037, "Success", "-"),
//     createData('Oct 15 ,2022, 2:53 AM', 'B000000041', 'Withdraw', 'qrs', 'Arya Stark', 32000, 30972.80, "Processing", "-"),
//     createData('Oct 20 ,2022, 6:23 AM', 'B000000091', 'Withdraw', 'qrs', 'Japan Shi', 17000, 16454.30, "Processing", "-"),
//     createData('Oct 21 ,2022, 7:23 AM', 'B000000101', 'Withdraw', 'qrs', 'Harvey Roxie', 22000, 21293.80, "Success", "-"),
//     createData('Oct 11 ,2022, 2:13 AM', 'B000000001', 'Withdraw', 'qrs', 'Cersei Snow', 20000, 19358, "Success", "-"),
//     createData('Oct 12 ,2022, 2:23 AM', 'B000000011', 'Withdraw', 'qrs', 'Jon Lannister', 22000, 21293.80, "Success", "-"),
//     createData('Oct 13 ,2022, 2:33 AM', 'B000000021', 'Withdraw', 'qrs', 'Jaime Lannister', 18000, 17422.20, "Success", "-"),
//     createData('Oct 27 ,2022, 8:23 AM', 'B000000111', 'Withdraw', 'qrs', 'United Kingdom', 30000, 29037.00, "Success", "-"),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "time",
    label: "Time",
    numeric: false,
    disablePadding: true,
    minWidth: 200
  },
  {
    id: "ref_no_sys_id",
    label: "Client Transaction Id / System Transaction Id",
    numeric: false,
    disablePadding: false,
    minWidth: 50
  },
  {
    id: "customer",
    label: "Bank",
    numeric: false,
    disablePadding: false,
    minWidth: 50
  },
  //   {
  //     id: "type",
  //     label: "Type",
  //     numeric: false,
  //     disablePadding: false,
  //     minWidth: 50
  //   },
  // {
  //     id: 'merchant',
  //     label: 'Merchant',
  //     numeric: false,
  //     disablePadding: false,
  //     minWidth: 50,
  // },
  //   {
  //     id: "customer",
  //     label: "Customer",
  //     minWidth: 150,
  //     numeric: false,
  //     disablePadding: false
  //   },
  {
    id: "amount",
    label: "Amount",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  {
    id: "totalFee",
    label: "Fee%",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },

  {
    id: "fee",
    label: "Fee",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  {
    id: "vat",
    label: "Vat 7%",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  //   {
  //     id: "feeRate",
  //     label: "Fee (%)",
  //     minWidth: 50,
  //     numeric: true,
  //     disablePadding: false
  //   },
  // {
  //   id: "vat",
  //   label: "Vat",
  //   minWidth: 50,
  //   numeric: true,
  //   disablePadding: false
  // },
  //   {
  //     id: "vatRate",
  //     label: "Vat (%)",
  //     align: "right",
  //     numeric: true,
  //     disablePadding: false
  //   },
  // {
  //   id: "amountNet",
  //   label: "Amount Net",
  //   minWidth: 50,
  //   numeric: true,
  //   disablePadding: false
  // },
  {
    id: "balance",
    label: "Amount With Fee",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  {
    id: "p_balance",
    label: "Prev Balance",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  {
    id: "n_balance",
    label: "New Balance",
    minWidth: 50,
    numeric: true,
    disablePadding: false
  },
  {
    id: "status",
    label: "Status",
    minWidth: 50,
    numeric: false,
    disablePadding: false
  }
  // {
  //     id: 'remark',
  //     label: 'Remark',
  //     minWidth: 50,
  //     numeric: false,
  //     disablePadding: false,
  // },
  // {
  //   id: "action",
  //   label: "Action",
  //   minWidth: 50,
  //   numeric: false,
  //   disablePadding: false
  // }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

function renderStatusBadge(status) {
  switch (status) {
    case "successful":
      return <ChipSuccess />;
    case "pending":
      return <ChipPending />;
    case "failed":
      return <ChipFail />;
    case "holded":
      return <ChipHold />;
    default:
      return null;
  }
}

function TableData() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const withdrawList = useRecoilValue(withdrawListState);

  const [openDialog, setOpenDialog] = useRecoilState(openDialogState);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            // aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
            // size='medium'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={withdrawList.length}
            />
            <TableBody>
              {stableSort(withdrawList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        {dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell align="center">
                        {row.clientTransactionId} <Divider />
                        {row.systemTransactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.bookBankName} <Divider />
                        {row.bookBankNumber} 
                        <Divider />
                        {row.bankName} 
                        {/* {bank.findIndex((e) => e.bankCode === row.bankCode) ===
                        -1
                          ? row.bankCode
                          : bank[
                              bank.findIndex(
                                (e) => e.bank_code === row.bankCode
                              )
                            ].bankName} */}
                      </TableCell>
                      {/* {row.type === "withdraw" ? (
                              <TableCell
                                align="right"
                                style={{ color: "#ED1C24" }}
                              >
                                {row.type}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="right"
                                style={{ color: "#22B14C" }}
                              >
                                {row.type}
                              </TableCell>
                            )} */}
                      {/* <TableCell align="left">
                              {row.customerName}
                            </TableCell> */}
                      <TableCell align="center">
                        {decimalTwoFormat(row.amount)}
                      </TableCell>
                      {/* <TableCell align="center">
                              {decimalTwoFormat(row.fee)} ({row.feeRate} %)
                            </TableCell>
                            <TableCell align="right">{row.feeRate}</TableCell>
                            <TableCell align="center">
                              {decimalTwoFormat(row.vat)} ({row.vatRate} %)
                            </TableCell> */}
                      {/* <TableCell align="right">{row.vatRate}</TableCell> */}
                      <TableCell align="center">
                        {decimalTwoFormat(row.totalFee)}
                      </TableCell>
                      <TableCell align="center">
                        {decimalTwoFormat(row.feeOutAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {decimalTwoFormat(row.feeOutAmount*0.07)}
                      </TableCell>
                      <TableCell align="center">
                        {decimalTwoFormat(row.amountWithFee)}
                      </TableCell>
                      <TableCell align="center">
                        {decimalTwoFormat(row.balanceBefore)}
                      </TableCell>
                      <TableCell align="center">
                        {decimalTwoFormat(row.balanceAfter)}
                      </TableCell>
                      <TableCell align="center">
                        {renderStatusBadge(row.status)}
                      </TableCell>
                      {/* <TableCell align="center">{row.remark}</TableCell> */}
                      {/* <TableCell align="center">
                              <Button onClick={handleClickOpen}>
                                <VisibilityIcon sx={{ color: "#A1A1A1" }} />
                              </Button>
                            </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={withdrawList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default TableData;
