import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ChipReject() {
  return (
    <Chip
      color="error"
      sx={{ color: "#fff", fontWeight: "bold" }}
      label="Rejected"
      icon={<CloseIcon />}
    />
  );
}
