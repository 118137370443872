import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Stack,
  Grid,
  TextField,
  Paper,
  Box
} from "@mui/material";
import { useAPI } from "../services/happypay-api";

const Settings = () => {
  const [callbackUrl, setCallbackUrl] = useState("");
  const [callbackWithdrawUrl, setCallbackWithdrawUrl] = useState("");
  const [name, setName] = useState("");
  const [minimumBalance, setMinimumBalance] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const api = useAPI();

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    api
      .profileMerchant()
      .then((data) => {
        const { setting, statusCode, name } = data;
        if (!statusCode) {
          setCallbackUrl(setting.callback ? setting.callback : "");
          setCallbackWithdrawUrl(setting.callbackWithdraw ? setting.callbackWithdraw : "")
          setName(name);
          setMinimumBalance(setting.minimum_balance);
          // setPassword(result.password)
          // setSecretKey(result.secret_key)
          // handleClose()
        }
      })
      .catch((error) => {
        const { message, statusCode } = error;
        alert(message);
      });
  }, []);

  const onSubmit = () => {
    api
      .updateMerchant(callbackUrl, callbackWithdrawUrl, minimumBalance, name)
      .then((data) => {
        const { status } = data;
        if (status) {
          alert("success");

          // handleClose()
        }
      })
      .catch((error) => {
        const { message } = error;
        alert(message);
        //   console.log(error);
      });
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Settings
        </Typography>
      </Box>
      <Paper sx={{ p: 3, py: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label="Name" value={name} disabled />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!isEditing}
              label="Callback Deposit URL"
              value={callbackUrl}
              onChange={handleChange(setCallbackUrl)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!isEditing}
              label="Callback Withdraw URL"
              value={callbackWithdrawUrl}
              onChange={handleChange(setCallbackWithdrawUrl)}
            />
          </Grid>
          <Grid item xs={12} align="right">
            {!isEditing && (
              <Button
                variant="contained"
                color="info"
                sx={{ color: "white" }}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                Change
              </Button>
            )}
            {isEditing && (
              <>
                <Button
                  variant="contained"
                  color="close"
                  sx={{ color: "white", mr: 2 }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};
export default Settings;
