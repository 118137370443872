import React, { useEffect, useState } from "react";
import { Typography, Grid, Table, Tabs, Tab, Box, Stack } from "@mui/material";
import WithdrawBalanceStat from "../components/settle/WithdrawBalanceStat";
import TableRequestTopups from "../components/topups/TableSettle";
import Payslip from "../components/topups/Payslip";
import DialogRequestTopup from "../components/topups/DialogRequestTopup";
import api, { useAPI } from "../services/happypay-api";
import { useRecoilState } from "recoil";
import { tableRequestTopupsState } from "../states/table-request-topups";
import TableSettle from "../components/topups/TableSettle";
import TableSettleHistory from "../components/topups/TableSettleHistory";

function Settle() {
  const [selectTab, setSelectTab] = useState(0);
  const api = useAPI();

  const [requestTopupsList, setRequestTopupsList] = useRecoilState(
    tableRequestTopupsState.requestTopupsList
  );
  const [requestTopupsPendingList, setRequestTopupsPendingList] =
    useRecoilState(tableRequestTopupsState.requestTopupsPendingList);

  useEffect(() => {
    api
      .getAllRequestTopups("pending")
      .then((data) => {
        setRequestTopupsPendingList(data);
      })
      .catch();
  }, []);

  useEffect(() => {
    api
      .getAllRequestTopups("approved,rejected")
      .then((data) => {
        setRequestTopupsList(data);
      })
      .catch();
  }, []);

  const handleChange = (event, value) => {
    setSelectTab(value);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4" component="h2">
          Settle
        </Typography>
      </Box>
      <Box>
        <WithdrawBalanceStat />
      </Box>
      <Box>
        <TableSettle />
      </Box>
      <Box>
        <TableSettleHistory />
      </Box>
      <DialogRequestTopup />
    </Stack>
  );
}
export default Settle;
