import React from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

//date
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useRecoilState } from "recoil";

import { depositListState } from "../../states/deposit-list";

function SearchDepositForm(props) {
  // const { exportTable } = props;
  const [depositFilterStatus, setDepositFilterStatus] = useRecoilState(
    depositListState.depositFilterStatus
  );
  const [depositFilterStart, setDepositFilterStart] = useRecoilState(
    depositListState.depositFilterStart
  );
  const [depositFilterEnd, setDepositFilterEnd] = useRecoilState(
    depositListState.depositFilterEnd
  );
  const [inputSearchHistoryDeposit, setInputSearchHistoryDeposit] =
    useRecoilState(depositListState.inputSearchHistoryDeposit);

  const handleChange = (event) => {
    setDepositFilterStatus(event.target.value);
  };

  const handleChangeStartDate = (startValue, a) => {
    setDepositFilterStart(startValue.format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleChangeEndDate = (endValue) => {
    setDepositFilterEnd(endValue.format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleSearchChange = (event) => {
    setInputSearchHistoryDeposit(event.target.value);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ px: 2 }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item sm={2}>
              <TextField
                value={inputSearchHistoryDeposit}
                onChange={handleSearchChange}
                fullWidth
                label="Search"
              />
            </Grid>
            <Grid item sm={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small">Status</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={depositFilterStatus}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={5}>All</MenuItem>
                  <MenuItem value={1}>Success</MenuItem>
                  <MenuItem value={2}>Pending</MenuItem>
                  <MenuItem value={3}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <DesktopDatePicker
                label="Start"
                inputFormat="DD/MM/YYYY HH:mm:ss"
                value={depositFilterStart}
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item sm={3}>
              <DesktopDatePicker
                label="End"
                inputFormat="DD/MM/YYYY HH:mm:ss"
                value={depositFilterEnd}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item sm={2}>
              <Box>
                <Button
                  onClick={props.exportCsv}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Export
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </>
  );
}

export default SearchDepositForm;
