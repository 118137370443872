import { useEffect } from "react";
import { authState } from "../../states/auth";
import { useRecoilState } from "recoil";
import { useAPI } from "../../services/happypay-api";

function GetMerchantProfile({ children }) {
  const [authToken, setAuthToken] = useRecoilState(authState.authToken);
  const [, setProfile] = useRecoilState(authState.profile);
  const api = useAPI();

  useEffect(() => {
    if (authToken) {
      api.profileMerchant().then((profile) => {
        setProfile(profile);
      });
    }
  }, [authToken]);

  return children;
}

export default GetMerchantProfile;
