import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState, useRecoilStoreID } from "recoil";
import { dialogRequestTopupState } from "../../states/dialog/request.topup";
import axios from "axios";

import Joi from "joi";
import { useAPI } from "../../services/happypay-api";

export default function DialogRequestTopup() {
  const api = useAPI();
  const [open, setOpen] = useRecoilState(dialogRequestTopupState.open);
  const [amount, setAmount] = useRecoilState(dialogRequestTopupState.amount);
  const [currency, setCurrency] = useRecoilState(
    dialogRequestTopupState.currency
  );
  const [responseStatus, setResponseStatus] = useRecoilState(
    dialogRequestTopupState.currency
  );

  const [withdrawBlance, setWithdrawBalance] = useRecoilState(dialogRequestTopupState.withdrawBalance)

  const onClose = () => {
    setOpen(!open);
    setAmount("");
    setCurrency("");
    setResponseStatus(null);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.currentTarget.value);
  };

  const handleChangeCurrency = (e) => {
    setCurrency(e.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const schema = Joi.object({
      amount: Joi.number().required()
    });

    const resultValidate = schema.validate({ amount: amount });
    if (resultValidate?.error) {
      alert(resultValidate?.error);
      return;
    }

    api
      .requestTopups(amount)
      .then(() => {
        setResponseStatus("success");
      })
      .catch((error) => {
        alert("error" + error.message);
      })
      .finally(() => {
        setAmount("");
        setCurrency("");
      });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
            Settle to withdraw wallet
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {responseStatus === "success" && (
            <Alert>Settle To Withdraw Wallet</Alert>
          )}
          <Box sx={{ p: 3, px: 10 }}>
            <Stack spacing={2}>
              <Box>
                <Typography>Deposit Wallet</Typography>
                <Typography sx={{ fontSize: "1.2rem" }}>{withdrawBlance}</Typography>
              </Box>
              <FormControl fullWidth>
                <FormLabel>Amount (THB)</FormLabel>
                <OutlinedInput value={amount} onChange={handleChangeAmount} />
              </FormControl>
              {/* <FormControl fullWidth>
                <FormLabel>Currency</FormLabel>
                <OutlinedInput
                  value={currency}
                  onChange={handleChangeCurrency}
                />
              </FormControl> */}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
