import { Chip } from "@mui/material";

export default function ChipHold() {
  return (
    <Chip
      color="warning"
      sx={{ color: "#fff", fontWeight: "bold" }}
      label="Holed"
    />
  );
}
