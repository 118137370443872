import { Chip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function ChipPending() {
  return (
    <Chip
      color="warning"
      sx={{ color: "#fff", fontWeight: "bold" }}
      label="Pending"
      icon={<AccessTimeIcon />}
    />
  );
}
