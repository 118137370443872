import { atom } from "recoil";

const customerList = atom({
  key: "table-customer.customerList",
  default: null
});


export const tableCustomerList = {
    customerList,
};
