import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  Container,
  useMediaQuery,
  CardMedia,
  Button
} from "@mui/material";
import { useAPI } from "../services/happypay-api";

//theme
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../states/auth";
import logo from "../assets/logo/logo.svg";

function ResetPassword() {
  const api = useAPI();
  const [isLogin, setIsLogin] = useRecoilState(authState.isLogin);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (e) => {
    // alert('onsummit')
    e.stopPropagation();
    e.preventDefault();

    api
      .resetPassword(searchParams.get("token"), password, confirmPassword)
      .then((data) => {
        const { id, accessToken, statusCode, message } = data;
        if (!statusCode) {
          alert("success");
        } else {
          alert(message);
        }
      })
      .catch((error) => {
        //console.log(error);
        const { message } = error.response.data;
        alert(message);
      });

    return false;
    //backendServiceApi.login(username, password);
  };

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  const matchMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  // console.log('matchMobile',matchMobile)

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <Box>
          <Grid item xs={12} sm={6} md={4}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  height: "100vh",
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Stack spacing={2} xs={12} md={4}>
                  <Typography
                    variant="h5"
                    color="#000"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardMedia
                      sx={{ objectFit: "contain", height: "10%" }}
                      component="img"
                      alt="imageIdcard"
                      image={logo}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Reset Password
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "100%"
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      color="inputLogin"
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-username">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-username fullWidth"
                        label="Username"
                        value={password}
                        onChange={handleChange(setPassword)}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "100%"
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      color="inputLogin"
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-username">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-username fullWidth"
                        label="Username"
                        value={confirmPassword}
                        onChange={handleChange(setConfirmPassword)}
                      />
                    </FormControl>
                  </Box>
                  <Button variant="contained" type="submit">
                    Reset Password
                  </Button>
                </Stack>
              </Box>
            </form>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default ResetPassword;
