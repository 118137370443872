import { atom } from "recoil";

const requestTopupsList = atom({
  key: "table-request-topups.requestTopupsList",
  default: null
});

const requestTopupsPendingList = atom({
  key: "table-request-topups.requestTopupsPendingList",
  default: null
});

export const tableRequestTopupsState = {
  requestTopupsList,
  requestTopupsPendingList
};
