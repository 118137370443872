import React, { useState, useContext } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";

import CustomerContext from "../../contexts/CustomerContext.jsx";
import { useAPI } from "../../services/happypay-api.js";
import bank from "../../constance/bank.json";

const DialogCusWithdraw = () => {
  const api = useAPI();

  const { openDialog, setOpenDialog } = useContext(CustomerContext);
  const [money, setMoney] = useState(0);
  const [bank_acc, setBankAcc] = useState("");
  const [accHolderName, setAccHolderName] = useState("");
  const [bankName, setBankName] = useState("");

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  const handleCloseClear = () => {
    setBankAcc("");
    setMoney(0);
    setAccHolderName("");
    setBankName("");
    setOpenDialog(false);
  };

  const onSubmit = () => {
    // console.log('money',money)
    // console.log('bank_acc',bank_acc)
    if (accHolderName && bankName && bank_acc && money > 0) {
      api
        .withdrawCustomer(
          money,
          bankName,
          bank_acc.substring(0, 3),
          bank_acc,
          accHolderName
        )
        .then(({ data }) => {
          const { statusCode } = data;
          if (!statusCode) {
            alert("success");
            handleClose();
            handleCloseClear();
            // setIsLogin(true);
          }
        })
        .catch((error) => {
          const { message } = error.response.data;
          alert(message);
          //   console.log(error);
        });
    } else {
      alert("please fill all data");
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <Box maxWidth={500}>
          <Paper sx={{ p: 2 }}>
            <DialogTitle textAlign={"left"} fontWeight={"bold"}>
              Withdraw
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                rowSpacing={1.5}
                sx={{ mt: 2 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    label="Amount"
                    value={money}
                    onChange={handleChange(setMoney)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Bank Name
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bankName}
                      label="Bank Name"
                      onChange={handleChange(setBankName)}
                    >
                      {bank.map((e) => {
                        return (
                          <MenuItem value={e.bank_code}>{e.bank_name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                                    <TextField sx={CssTextField} fullWidth required label="Bank Name" value={bankName} onChange={handleChange(setBankAcc)} />
                                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Bank Account"
                    value={bank_acc}
                    onChange={handleChange(setBankAcc)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Account Holder Name"
                    value={accHolderName}
                    onChange={handleChange(setAccHolderName)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={onSubmit} sx={{ color: "white" }}>
                Submit
              </Button>
            </DialogActions>
          </Paper>
        </Box>
      </Dialog>
    </>
  );
};

export default DialogCusWithdraw;
