import React, { useState } from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";
import { useAPI } from "../services/happypay-api";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const api = useAPI();

  const [email, setEmail] = useState("");
  const [merchant, setMerchant] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const navigate = useNavigate();

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  // useEffect(() => {
  //     dragonpayService
  //         .getPostUrl()
  //         .then(({ data }) => {
  //             const { success, result } = data;
  //             if (success) {
  //                 setDepositUrl(result.post_back_url)
  //                 setWithdrawUrl(result.post_back_withdraw_url)
  //                 // setPassword(result.password)
  //                 // setSecretKey(result.secret_key)
  //                 // handleClose()
  //             }
  //         })
  //         .catch((error) => {
  //             const { message } = error.response.data;
  //             alert(message)
  //             //   console.log(error);
  //         });
  // }, []);

  const validate = () => {
    if (password !== confirmPass) {
      alert("Your password and confirm password not match");
      return;
    }
    if (password.length < 6) {
      alert("Your password needs a minimum of six characters");
      return;
    }
    if (password.search(/[a-z]/) < 0) {
      alert("Your password needs a lower case letter");
      return;
    }
    if (password.search(/[A-Z]/) < 0) {
      alert("Your password needs a upper case letter");
      return;
    }
    if (password.search(/[0-9]/) < 0) {
      alert("Your password needs a number");
      return;
    }
    onSubmit();
  };

  const onSubmit = () => {
    api
      .registerMerchant(merchant, email, phone, password)
      .then((data) => {
        const { success, result } = data;
        if (success) {
          alert("register success");
          navigate("/");

          // handleClose()
        }
      })
      .catch((error) => {
        const { message } = error;
        alert(message);
        //   console.log(error);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5
            }}
            variant="h4"
            component="h2"
          >
            Register Merchant Happypay
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <TextField
            required
            fullWidth
            label="Email"
            value={email}
            onChange={handleChange(setEmail)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <TextField
            required
            fullWidth
            label="Merchant Name"
            value={merchant}
            onChange={handleChange(setMerchant)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <TextField
            required
            fullWidth
            label="Phone"
            value={phone}
            onChange={handleChange(setPhone)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <TextField
            required
            fullWidth
            label="Password"
            value={password}
            onChange={handleChange(setPassword)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <TextField
            required
            fullWidth
            label="Confirm Password"
            value={confirmPass}
            onChange={handleChange(setConfirmPass)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 8, marginRight: 8 }}>
          <Button fullWidth variant="contained" onClick={validate}>
            Register
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default Register;
