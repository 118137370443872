import { atom } from "recoil";
import dayjs from "dayjs";

const depositFilterStatus = atom({
  key: "deposit-list.depositFilterStatus",
  default: 5
});

const depositFilterStart = atom({
  key: "deposit-list.depositFilterStart",
  default: dayjs().startOf('month')
});

const depositFilterEnd = atom({
  key: "deposit-list.depositFilterEnd",
  default: dayjs().endOf('day')
});

const depositSum = atom({
  key: "deposit-list.depositSum",
  default: 0
});

const depositSumFee = atom({
  key: "deposit-list.depositSumFee",
  default: 0
});

const totalDepositToday = atom({
  key: "deposit-list.totalDepositToday",
  default: 0
});

const totalDeposit = atom({
  key: "deposit-list.totalDeposit",
  default: 0
});

const totalPendindDeposit = atom({
  key: "deposit-list.totalPendindDeposit",
  default: 0
});

const totalPendingDeposit = atom({
  key: "deposit-list.totalPendingDeposit",
  default: 0
});

const openDepositDialog = atom({
  key: "deposit-list.openDepositDialog",
  default: false
});

const inputSearchHistoryDeposit = atom({
  key: "deposit-list.inputSearchHistoryDeposit",
  default: ""
});

const pendingDepositList = atom({
  key: "deposit-list.pendingDepositList",
  default: null
});

const currentPageHistoryList = atom({
  key: "deposit-list.currentPageHistoryList",
  default: 0
});

const currentPagePendingList = atom({
  key: "deposit-list.currentPagePendingList",
  default: 0
});

const depositBalance = atom({
  key: "deposit-list.depositBalance",
  default: 0
});

const todayDeposit = atom({
  key: "deposit-list.todayDeposit",
  default: 0
});

const historyDepositList = atom({
  key: "deposit-list.historyDepositList",
  default: []
});

const inputSearchPendingDeposit = atom({
  key: "deposit-list.inputSearchPendingDeposit",
  default: ""
});


const openQrcodeDialog = atom({
  key: "deposit-list.openQrcodeDialog",
  default: ""
});

const inputAmountDeposit = atom({
  key: "deposit-list.inputAmountDeposit",
  default: 0
});

const qrCodeDeposit = atom({
  key: "deposit-list.qrcodeDeposit",
  default: ""
});

const refNoDeposit = atom({
  key: "deposit-list.refNoDeposit",
  default: ""
});

const merchantRefNo = atom({
  key: "deposit-list.merchantRefNo",
  default: ""
});

const amountGenQr = atom({
  key: "deposit-list.amountGenQr",
  default: 0
});

const statusQr = atom({
  key: "deposit-list.statusQr",
  default: false
});

export const depositListState = {
  depositFilterStatus,
  depositFilterStart,
  depositFilterEnd,
  depositSumFee,
  depositSum,
  totalDepositToday,
  totalDeposit,
  historyDepositList,
  todayDeposit,
  depositBalance,
  inputSearchPendingDeposit,
  currentPagePendingList,
  currentPageHistoryList,
  pendingDepositList,
  inputSearchHistoryDeposit,
  openDepositDialog,
  totalPendingDeposit,
  totalPendindDeposit,
  openQrcodeDialog,
  inputAmountDeposit,
  qrCodeDeposit,
  refNoDeposit,
  merchantRefNo,
  amountGenQr,
  statusQr
};
