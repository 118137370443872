import React, { useState } from 'react'
import CustomDrawer from './CustomDrawer';
import {
  Paper,
} from "@mui/material";
import { Outlet } from 'react-router-dom';

function LayoutMain({ children }) {
  return (
    <>
      <CustomDrawer children={<Outlet />} />
    </>
  );
}

export default LayoutMain;
